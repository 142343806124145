<template functionnal>
  <section class="section">
    <div class="hero">
      <div class="hero-body mt-2">
        <div class="content u-text-center">
          <h1>
            Bienvenue à <br /><br />
            <b class="headline-4">Femmes Initiatives</b>
          </h1>
          <h6 class="font-alt font-light">
            <br /><br />
            7 rue Lachelier, 75013 PARIS <br />
            du <b>lundi</b> au <b>vendredi</b> <br />
            de <b>09H</b> à <b>12H30</b> et de <b>14H</b> à <b>17H30</b><br />
          </h6>

          <div class="logos">
            <b>Nos formations sont certifiées:</b>

            <div class="images">
              <div class="quali-text">
                <a
                  target="_blank"
                  href="https://travail-emploi.gouv.fr/formation-professionnelle/acteurs-cadre-et-qualite-de-la-formation-professionnelle/article/qualiopi-marque-de-certification-qualite-des-prestataires-de-formation"
                >
                  <img src="@/assets/images/LogoQualiopi.png" alt="Logo Qualiopi" />
                </a>
                <div class="qualit-text-content">
                  La certification qualité a été délivrée au titre de la catégorie d'action suivante
                  :<br />
                  <b>ACTIONS DE FORMATION</b>
                  <br>
                  <a href="/certificat-qualiopi.pdf" download>
                    <img src="/certificat-qualiopi.png" alt="Certificat Qualiopi" />
                  </a>
                </div>
              </div>
              <a href="https://www.data-dock.fr/" target="_blank">
                <img src="@/assets/images/datadock.png" alt="Logo Datadock" />
              </a>
            </div>
          </div>

          <a
            href="https://fr-fr.facebook.com/pages/category/Nonprofit-Organization/Femmes-Initiatives-1639681316351534/"
            target="_blank"
          >
            <button class="btn-primary btn-large font-semibold">
              Suivez notre actualité sur notre page facebook
            </button>
          </a>

          <div class="mt-4">
            <a href="/general_24-25.pdf" download>Télécharger la plaquette</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.quali-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qualit-text-content {
  width: 13rem;
  font-size: 0.7rem;
  margin-left: -1rem;
}

.section {
  position: relative;
  margin-top: -90px;
}
.section:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 100vw;
  height: 100%;
  z-index: -1;
  opacity: 0.15;
  background-image: url("../assets/images/logo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
}

.logos {
  font-size: 1.5rem;
  margin: 4rem 0;

  .images {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 50% 50%;
    font-size: 1rem;

    img {
      border: 2px solid #333;
      border-radius: 1rem;
      // opacity: 0.8;
      width: 250px;
      &:first-child {
        margin-right: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 830px) {
  .hero-body {
    padding: 0;
  }
  .content {
    margin: 0;
    width: 100vw;
  }
  .btn-primary {
    font-size: 12px;
    margin: 15px;
  }
  .section:before {
    background-position: center;
    background-size: 100%;
  }
}
</style>
